import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";

function IndexPage() {
  return (
    <Layout>
      <SEO
        title="Home"
        keywords={[`Neurology`, `Autism`, `children`, `medicine`]}
      />
      <div class="flex-row text-center">
        <button class="w-2/5 mr-8 bg-transparent hover:bg-teal-300 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-green-300 hover:border-transparent rounded">
          tema
        </button>
        <button class="w-2/5 bg-transparent hover:bg-teal-300 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-green-300 hover:border-transparent rounded">
          tema
        </button>
      </div>
      <div className="text-center">
        <h2 className="bg-purple-400 text-5xl font-bold inline-block my-8 p-3">
          Neurologia
        </h2>
      </div>
      <div class="flex-row text-center">
        <button class="w-2/5 mr-8 bg-transparent hover:bg-teal-300 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-green-300 hover:border-transparent rounded">
          tema
        </button>
        <button class="w-2/5 bg-transparent hover:bg-teal-300 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-green-300 hover:border-transparent rounded">
          tema
        </button>
      </div>
    </Layout>
  );
}

export default IndexPage;
